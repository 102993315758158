@tailwind base;
@tailwind components;
@tailwind utilities;

/* index.css */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

/* index.css */

/* index.css */
.qr-code-card {
  background: white;
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), 0 4px 8px rgba(0, 0, 0, 0.1);
  transform: translateY(0);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.qr-code-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3), 0 6px 12px rgba(0, 0, 0, 0.2);
}

.qr-code-container {
  position: relative;
}

/* index.css */

.countdown-container {
  width: 100%;
  height: 96px;
  border-radius: 1rem;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 70%;
}

.countdown-main {
  display: flex;
  gap: 1.5rem;
}

.timer {
  background: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(5px);
  border-radius: 1rem;
  padding: 1rem;
  min-width: 96px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.countdown-element {
  font-size: 2rem;
  font-weight: 600;
  color: #fff;
}

.timer p {
  font-size: 1rem;
  text-transform: uppercase;
  color: #fff;
  margin-top: 0.25rem;
  text-align: center;
}

/* Add this CSS to your stylesheet */
.input-center {
  text-align: center; /* Center horizontally */
  padding-top: 0; /* Adjust vertical padding if needed */
  padding-bottom: 0; /* Adjust vertical padding if needed */
}

.placeholder-center::placeholder {
  text-align: center;
}
